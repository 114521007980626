footer {
    width: 100%;
    background: #1c2024;
    margin-top: 50px;
}

.footer_wrapper {
    width: 100%;
    padding: 4rem 0rem 0rem 0rem;
}

.footer_wrapper .footer_row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 30px;
}

.footer_wrapper .footer_row .footer_item .logo_container {
    max-width: 140px;
}

.footer_wrapper .footer_row .footer_item .logo_container img {
    max-width: 100%;
}

.footer_wrapper .footer_row .footer_item .content {
    margin-top: 30px;
    max-width: 350px;
}

.footer_wrapper .footer_row .footer_item .content p {
    line-height: 1.6;
    color: #9ea1a5;
    font-weight: 600;
}

.footer_wrapper .footer_row .footer_item .content h2 {
    font-size: 30px;
}

.footer_wrapper .footer_row .footer_item .content h2 .contact_icon {
    margin-bottom: -5px;
    margin-right: 2px;
}

.footer_wrapper .footer_row .footer_item .contact_mail {
    margin-left: 40px;
}

.footer_wrapper .footer_row .footer_item .section_heading {
    margin-bottom: 20px;
}

.footer_wrapper .footer_row .footer_item .section_heading h2 {
    font-size: 30px;
}

.footer_wrapper .footer_row .footer_item .section_links ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.footer_wrapper .footer_row .footer_item .section_links ul li a {
    color: #9ea1a5;
    font-size: 18px;
    font-weight: 600;
}

.footer_wrapper .copyright {
    width: 100%;
    padding: 20px;
    border-top: 1px solid #2E3135;
}

.footer_wrapper .copyright p {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.footer_wrapper .copyright .copyright_icon {
    margin-bottom: -3px;
    margin-right: 5px;
}