.crm_wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.crm_content {
    width: calc(100% - 240px);
    background: #141b2d;
}

@media (max-width: 768px) {
    .crm_content {
        width: calc(100% - 150px);
    }
}

@media (max-width: 600px) {
    .crm_content {
        width: calc(100% - 120px);
    }
}

.force-landscape {
    transform: rotate(90deg);
    transform-origin: center;
    width: 100vh;
    height: 100vw;
    background: #141b2d;
}