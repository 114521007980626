.super_admin_page_wrapper {
    width: 100%;
    padding: 40px;
}

@media screen and (max-width: 600px) {
    .super_admin_page_wrapper {
        padding: 40px 10px;
    }
}

.super_admin_page_wrapper .location_indicator {
    display: flex;
    align-items: center;
}

.super_admin_page_wrapper .location_indicator .angle_right {
    margin-bottom: -3px;
}

.super_admin_page_wrapper .location_indicator p {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}

.super_admin_page_wrapper .location_indicator p span {
    color: #fec600;
}

.super_admin_page_wrapper .profile_content {
    margin-top: 30px;
    padding: 0px 30px;
}

.super_admin_page_wrapper .profile_content .profile_data_item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    max-width: fit-content;
}

.super_admin_page_wrapper .profile_content .profile_data_item .identifier {
    min-width: 230px;
    font-size: 18px;
    font-weight: 500;
}

.super_admin_page_wrapper .profile_content .profile_data_item .value {
    font-size: 18px;
    font-weight: 500;
}

.super_admin_page_wrapper .profile_content .profile_data_item .edit_btn {
    padding: 4px 8px;
    color: white;
    background: #fec600;
    border: 1px solid #fec600;
    font-weight: 500;
    margin-left: 10px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.super_admin_page_wrapper .profile_content .profile_data_item .edit_btn:hover {
    background: transparent;
    cursor: pointer;
}

.super_admin_page_wrapper .profile_content .profile_data_item input {
    background: transparent;
    border: none;
    color: white;
    outline: none;
    border-bottom: 1px solid #cccc;
    padding: 4px 8px;
    transition: all 0.3s ease-in-out;
    min-width: 280px;
}

.super_admin_page_wrapper .profile_content .profile_data_item input:hover {
    border-bottom: 1px solid #fec600;
}

.super_admin_page_wrapper .profile_content .reset_text {
    margin-top: 40px;
    display: block;
    font-size: 23px;
    font-weight: 500;
}

.super_admin_page_wrapper .profile_content .profile_data_item .password_toggle_icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.super_admin_page_wrapper .profile_content .button_container {
    max-width: 610px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.super_admin_page_wrapper .profile_content .button_container button {
    padding: 8px 16px;
    color: white;
    background: #fec600;
    border: 1px solid #fec600;
    font-weight: 500;
    margin-left: 10px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.super_admin_page_wrapper .profile_content .button_container button:hover {
    cursor: pointer;
    color: #fec600;
    background: transparent;
}