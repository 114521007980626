.admin_page_wrapper {
    width: 100%;
    padding: 40px;
}


@media screen and (max-width: 600px) {
    .admin_page_wrapper {
        padding: 40px 10px;
    }
}

.admin_page_wrapper .warning {
    color: #fec600;
    font-weight: 500;
    margin-top: 10px;
}

.admin_page_wrapper .location_indicator {
    display: flex;
    align-items: center;
}

.admin_page_wrapper .location_indicator .angle_right {
    margin-bottom: -3px;
}

.admin_page_wrapper .location_indicator p {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}

.admin_page_wrapper .location_indicator p  span {
    color: #fec600;
}

.admin_page_wrapper .welcome_note {
    text-align: left;
}

.admin_page_wrapper .welcome_note h2 {
    font-size: 28px;
    font-weight: 500;
}

.admin_page_wrapper .welcome_note h2 span {
    font-weight: 600;
    text-transform: uppercase;
}

.admin_page_wrapper .dashboard_data {
    margin-top: 40px;
}

.admin_page_wrapper .dashboard_data .dashboard_data_row {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
}

.admin_page_wrapper .dashboard_data .dashboard_data_row .dashboard_data_item {
    padding: 20px 30px;
    background: #1f2940;
    min-width: 200px; 
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border-bottom:1px solid #fec600;
}

.admin_page_wrapper .dashboard_data .dashboard_data_row .dashboard_data_item h3 {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 500;
}

.admin_page_wrapper .dashboard_data .dashboard_data_row .dashboard_data_item .data_value {
    font-size: 26px;
    font-weight: 600;
}

.admin_page_wrapper .line_chart_container {
    width: 100%;
    margin-top: 50px;
}

.admin_page_wrapper .pie_chart_container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 80px;
    flex-wrap: wrap;
}

@media screen and (max-width: 1300px) {
    .admin_page_wrapper .dashboard_data .dashboard_data_row {
        flex-wrap: wrap;
    }
}