.crm_page_wrapper {
    width: 100%;
}

.crm_page_wrapper .user_details h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.crm_page_wrapper .user_details .data_row {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    max-width: 500px;
}

.crm_page_wrapper .user_details .data_row .key {
    display: block;
    font-size: 18px;
    font-weight: 600;
    min-width: 120px;
    margin-right: 10px;
}

.crm_page_wrapper .user_details .data_row .value {
    font-size: 18px;
    font-weight: 500;
}

.crm_page_wrapper .user_details .pass_change_text {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 900;
}

.crm_page_wrapper .user_details .pass_field {
    min-width: 180px !important;
}

.crm_page_wrapper .user_details .data_row input {
    padding: 6px 12px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    border-radius: 4px;
    min-width: 280px;
    transition: all 0.3s ease-in-out;
    color: white;
}

.crm_page_wrapper .user_details .data_row input:hover {
    border-bottom: 1px solid #fce600;
}

.crm_page_wrapper .user_details .passwordtoggleicon {
    font-size: 18px;
    position: absolute;
    right: 40px;
    cursor: pointer;
}

.crm_page_wrapper .user_details .error {
    color: red;
    margin: 10px 0px;
    font-weight: 600;
    max-width: 500px;
    text-align: center;
    
}

.crm_page_wrapper .user_details .button_container {
    max-width: 500px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.crm_page_wrapper .user_details .button_container button {
    padding: 8px 12px;
    background: #FEC600;
    border: 1px solid #FEC600;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    transition: all 0.3s ease-in;
    min-width: 141px;
}

.crm_page_wrapper .user_details .button_container button:hover {
    background: transparent;
    cursor: pointer;
}