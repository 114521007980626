.navbar_wrapper {
    padding: 20px;
    position: relative;
}

.navbar_wrapper .logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar_wrapper .logo img {
    max-width: 150px;
}

.navbar_wrapper ul {
    display: flex;
    gap: 30px;
}

.navbar_wrapper ul li a {
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

.navbar_wrapper ul li .btn {
    background: #fec600;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #fec600;
    outline: none;
    margin-top: -4px;
}

.navbar_wrapper ul li .btn:hover {
    cursor: pointer;
    border: 1px solid #fec600;
    background: transparent;
    color: #fec600;
}

.navbar_wrapper .burger {
    position: absolute;
    cursor: pointer;
    right: 2%;
    top: 8px;
    display: none;
    z-index: 2;
}

.navbar_wrapper .burger .burger_line {
    background-color: white;
    border-radius: 4px;
    display: inline-block;
    height: 4px;
    margin-right: -.8rem;
    position: relative;
    transform: rotate(.5turn);
    width: 1.6rem;
}


.navbar_wrapper .burger .burger_line::before {
    content: "";
    left: 0;
    position: absolute;
    transition: all .2s;
    background-color: white;
    border-radius: 4px;
    display: inline-block;
    height: 4px;
    width: 1.2rem;
    top: -.5rem;
}

.navbar_wrapper .burger .burger_line::after {
    background-color: white;
    border-radius: 4px;
    display: inline-block;
    height: 4px;
    width: 2.2rem;
    content: "";
    left: 0;
    position: absolute;
    transition: all .2s;
    top: .5rem;
}

@media screen and (max-width: 980px) {
    .navbar_wrapper {
        padding: 20px 15px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .navbar_wrapper ul {
        flex-direction: column;
        align-items: center;
    }

    .navbar_wrapper .navigation {
        margin-top: 40px;
        max-height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .navbar_wrapper .burger {
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        height: 45px;
        justify-content: center;
        width: 60px;
    }

    .navbar_wrapper .nav_open {
        max-height: 350px;
        opacity: 1;
        visibility: visible;
    }

    .navbar_wrapper .nav_active .burger_line::before {
        top: 0;
        transform: rotate(135deg);
        width: 1.4rem;
    }

    .navbar_wrapper .nav_active .burger_line::after {
        top: 0;
        transform: rotate(-135deg);
        width: 1.4rem;
    }

    .navbar_wrapper .nav_active .burger_line {
        background: transparent;
    }
}