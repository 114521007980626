.toast_container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1000;
}

.toast {
    padding: 12px 16px;
    color: white;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
    font-size: 18px;
}

.toast_info {
    background-color: #fec600;
    border: 1px solid #fec600;
}

.toast_success {
    background-color: green;
    border: 1px solid green;
}

.toast_error {
    background-color: red;
    border: 1px solid red;
}

.toast_button {
    background: none;
    border: none;
    outline: none;
}

.toast_close {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    color: white;
    position: absolute;
    right: 10px;
    top: 5px;
}