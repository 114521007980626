.admin_page_wrapper {
    width: 100%;
    padding: 40px;
}

@media screen and (max-width: 600px) {
    .admin_page_wrapper {
        padding: 40px 10px;
    }
}

.admin_page_wrapper .add_cust_btn {
    background: #fec600;
    color: white;
    border: 1px solid #fec600;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.admin_page_wrapper .add_cust_btn:hover {
    cursor: pointer;
    background: transparent;
    color: #fec600;
}

.admin_page_wrapper .welcome_note {
    text-align: left;
}

.admin_page_wrapper .welcome_note h2 {
    font-size: 28px;
    font-weight: 500;
}

.admin_page_wrapper .customers_details {
    width: 100%;
    margin-top: 50px;
}

.admin_page_wrapper .customers_details .customers_list {
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
    /* table-layout: fixed; */
}

.admin_page_wrapper .customers_details .customers_list th,
.admin_page_wrapper .customers_details .customers_list td {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 10px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccc;
}

.admin_page_wrapper .customers_details .customers_list th {
    font-size: 18px;
    background-color: #f4f4f419;
    font-weight: 600;
}

.admin_page_wrapper .customers_details .customers_list tr:hover {
    background-color: #cccccc36;
}

.admin_page_wrapper .customers_details .customers_list td {
    vertical-align: middle;
    border-bottom: 1px solid #cccccc2d;
}

.admin_page_wrapper .customers_details .customers_list .edit_icon {
    cursor: pointer;
    font-size: 20px;
}

.admin_page_wrapper .customers_details .customers_list .edit_icon:hover {
    cursor: pointer;
}

/* overlay css  */

.popup_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_background .popup_content {
    width: 100%;
    max-width: 500px;
    padding: 30px 40px;
    background: #1E1E2F;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    overflow: auto;
    max-height: 95vh;
    overflow: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}

.popup_background .popup_content::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Edge, Safari */
  }

.popup_background .popup_content .popup_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.popup_background .popup_content .popup_title h3 {
    font-size: 26px;
}

.popup_background .popup_content .popup_title .close_icon {
    font-size: 26px;
}

.popup_background .popup_content .popup_title .close_icon:hover {
    cursor: pointer;
}

.popup_background .popup_content .form_container {
    margin-top: 40px;
    width: 100%;
}

.popup_background .popup_content .form_container .input_row {
    width: 100%;
    margin-top: 30px;
    position: relative;
}

.popup_background .popup_content .form_container .input_row label {
    display: block;
    font-size: 18px;
    margin-bottom: 7px;
    font-weight: 500;
}

.popup_background .popup_content .form_container .input_row input {
    width: 100%;
    padding: 8px 12px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3sease-in-out;
    color: white;
}

.popup_background .popup_content .form_container .input_row input:focus {
    border-bottom-color: #fec600;
}

.popup_background .popup_content .form_container .input_row .password_toggle_icon {
    position: absolute;
    right: 10px;
    top: 35px;
}

.popup_background .popup_content .form_container .input_row .password_toggle_icon:hover {
    cursor: pointer;
}

.popup_background .popup_content .form_container .button_container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.popup_background .popup_content .form_container .button_container button {
    background: #fec600;
    color: white;
    border: 1px solid #fec600;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    min-width: 141px;
}

.popup_background .popup_content .form_container .button_container button:hover {
    cursor: pointer;
    background: transparent;
    color: #fec600;
}

.popup_background .popup_content .form_container .subscription_status {
    margin-bottom: 30px;
}

.popup_background .popup_content .form_container .subscription_status h4 {
    font-size: 22px;
    margin-bottom: 5px;
}

.popup_background .popup_content .form_container .subscription_status p {
    font-size: 18px;
    font-weight: 500;
}

.popup_background .popup_content .form_container .susbcription_purchase h4 {
    font-size: 22px;
    margin-bottom: 5px;
}

.popup_background .popup_content .form_container .susbcription_purchase p {
    font-size: 18px;
    font-weight: 500;
}

.popup_background .popup_content .form_container .susbcription_purchase .counter_btn {
    background: #fec600;
    padding: 4px 8px;
    color: white;
    align-items: center;
    font-size: 18px;
    border-radius: 8px;
    margin-right: 8px;
    border: 1px solid #fec600;
}

.popup_background .popup_content .form_container .susbcription_purchase .counter_btn:hover {
    cursor: pointer;
    background: transparent;
}


.popup_background .popup_content .form_container .susbcription_purchase .button_container {
    margin-left: 15px;
    max-width: 240px;
    margin-top: 0px;
}

.popup_background .popup_content .form_container .susbcription_purchase .button_container .btn {
    background: #fec600;
    color: white;
    padding: 6px 12px;
    font-weight: 500;
    border-radius: 4px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #fec600;
}

.popup_background .popup_content .form_container .susbcription_purchase .button_container .btn:hover {
    cursor: pointer;
    background: transparent;
}

.popup_background .popup_content .form_container .password_change {
    margin-top: 30px;
}

.popup_background .popup_content .form_container .password_change h4 {
    font-size: 22px;
    margin-bottom: 5px;
}

.popup_background .popup_content .form_container .password_change .item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
}

.popup_background .popup_content .form_container .password_change .item label {
    font-size: 18px;
    font-weight: 500;
    min-width: 180px;
}

.popup_background .popup_content .form_container .password_change .item input {
    width: 100%;
    padding: 6px 12px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3sease-in-out;
    color: white;
    margin-left: 10px;
}

.popup_background .popup_content .form_container .password_change .item input:focus {
    border-bottom: 1px solid #fec600;
}

.popup_background .popup_content .form_container .password_change .item .password_toggle_icon {
    position: absolute;
    right: 10px;
}

.popup_background .popup_content .form_container .password_change .item .password_toggle_icon:hover {
    cursor: pointer;
}

.popup_background .popup_content .form_container .button_container button {
    background: #fec600;
    color: white;
    border: 1px solid #fec600;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.popup_background .popup_content .form_container .button_container button:hover {
    cursor: pointer;
    background: transparent;
    color: #fec600;
}

.popup_background .popup_content .form_container .value {
    display: block;
    font-size: 18px;
    margin-bottom: 7px;
    font-weight: 500;
    margin-left: 20px;
}


.popup_background .popup_content .form_container .toggle_switch {
    width: 40px;
    height: 20px;
    background: red;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s;
    margin-bottom: 5px;
    margin-left: 20px;
}

.popup_background .popup_content .form_container .toggle_switch.on {
    background-color: #4caf50;
}

.popup_background .popup_content .form_container .toggle_switch .slider {
    width: 18px;
    height: 18px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    transition: left .3s;
}

.popup_background .popup_content .form_container .toggle_switch.on .slider {
    left: 22px;
}

.refresh_icon {
    font-size: 28px;
    cursor: pointer;
    margin-right: 10px;
}