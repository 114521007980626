.page_wrapper {
    width: 100%;
}

.page_wrapper .button_container {
    margin-top: 20px;
}

.page_wrapper .button_container button {
    padding: 10px 16px;
    background: #FEC600;
    color: white;
    border: 1px solid #FEC600;
    font-size: 18px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.page_wrapper .button_container button:hover {
    cursor: pointer;
    background: transparent;
    color: #FEC600;
}

.page_wrapper .container .banner_wrapper {
    width: 100%;
    position: relative;
    min-height: 40vh;
    padding: 6rem 0rem;
}

.page_wrapper .container .banner_wrapper .banner_overlay {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page_wrapper .container .banner_wrapper .banner_overlay .img_overlay {
    position: absolute;
    z-index: 1;
    right: 0;
    max-width: fit-content;
}

.page_wrapper .container .banner_wrapper .banner_overlay .text {
    width: 50%;
}

.page_wrapper .container .banner_wrapper .banner_overlay .text h2 {
    font-size: 50px;
    text-transform: capitalize;
}

.page_wrapper .container .banner_wrapper .banner_overlay .banner_img {
    max-width: 500px;
}

.page_wrapper .container .banner_wrapper .banner_overlay .banner_img img {
    max-width: 100%;
}

.page_wrapper .container .banner_wrapper .banner_overlay .text p {
    margin-top: 10px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.7;
    color: #9ea1a5;
}

.page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons {
    margin-top: 20px;
    gap: 40px;
}

.page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons button {
    padding: 10px 16px;
    font-size: 20px;
    background: linear-gradient(90deg, #edbe16 0%, #FEC600 100%);
    color: white;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #FEC600;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease-in-out;
    z-index: 1;
}

.page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons button::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    transition: all 0.5s ease-in-out;
    z-index: 0;
}

.page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons button:hover {
    cursor: pointer;
}

.page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons button:hover::after {
    left: 100%;
}

.page_wrapper .primary_about {
    width: 100%;
    padding: 4rem 0rem;
}

.page_wrapper .primary_about .content_wrapper {
    position: relative;
}

.page_wrapper .primary_about .content_wrapper .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page_wrapper .primary_about .content_wrapper .content .text {
    max-width: 50%;
    text-align: left;
}

.page_wrapper .primary_about .content_wrapper .content .text h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.page_wrapper .primary_about .content_wrapper .content .text p {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    color: #9ea1a5;
}

.page_wrapper .primary_about .content_wrapper .content .order_2 {
    order: 2;
}

.page_wrapper .primary_about .content_wrapper .about_overlay {
    position: absolute;
    top: 170px;
    z-index: -1;
}

.page_wrapper .primary_about .content_wrapper .about_overlay .overlay_img_2 {
    left: 0px;
    position: absolute;
    top: 8px;
}

.page_wrapper .home_services_wrapper {
    width: 100%;
    padding: 4rem 0rem;
}

.page_wrapper .home_services_wrapper .heading {
    text-align: center;
}

.page_wrapper .home_services_wrapper .heading p {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 50;
}

.page_wrapper .home_services_wrapper .heading h2 {
    font-size: 40px;
    line-height: 1.4;
}

.page_wrapper .home_services_wrapper .services_content {
    width: 100%;
    margin-top: 40px;
}

.page_wrapper .home_services_wrapper .services_content .services_row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px;
}

.page_wrapper .home_services_wrapper .services_content .services_row .services_item {
    border-radius: 7px;
    background-image: linear-gradient(40deg, #171B1E 0%, #162431 50%, #2F353D 100%);
    padding: 50px 44px 25px 43px;
    text-align: center;
    transition: 0.4s;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-width: 280px;
}

.page_wrapper .home_services_wrapper .services_content .services_row .services_item:hover {
    cursor: pointer;
}

.page_wrapper .home_services_wrapper .services_content .services_row .services_item .icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.011764705882352941);
    border-top: 4px solid #FEC600;
    line-height: 100px;
    margin-bottom: 30px;
}

.page_wrapper .home_services_wrapper .services_content .services_row .services_item .icon .service_icon {
    font-size: 26px;
}

.page_wrapper .home_services_wrapper .services_content .services_row .services_item .content h3 {
    font-size: 26px;
    margin-bottom: 10px;
}

.page_wrapper .home_services_wrapper .services_content .services_row .services_item .content p {
    font-size: 16px;
    font-weight: 500;
    color: #9ea1a5;
}

.page_wrapper .testimonials_wrapper {
    width: 100%;
    padding: 6rem 0rem;
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    border-top: 3px solid #1C2024;
    border-bottom: 3px solid #1C2024;
}

.page_wrapper .testimonials_wrapper .text {
    text-align: left;
    max-width: 60%;
}

.page_wrapper .testimonials_wrapper .text p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
}

.page_wrapper .testimonials_wrapper .text h2 {
    font-size: 40px;
    margin-bottom: 10px;
}


.page_wrapper .testimonials_wrapper .text .testimonial_content {
    font-size: 16px;
    color: #9ea1a5;
}

.page_wrapper .testimonials_wrapper .numbers {
    text-align: ce;
}

.page_wrapper .testimonials_wrapper .numbers .row {
    display: flex;
}

.page_wrapper .testimonials_wrapper .numbers .row .item {
    border: 1px solid #dbdcde68;
    padding: 20px;
    flex: 1;
    text-align: center;
    width: 200px;
}

.page_wrapper .testimonials_wrapper .numbers .row .item h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 8px;
}

.page_wrapper .testimonials_wrapper .numbers .row .item p {
    color: #9ea1a5;
    font-size: 18px;
}

.page_wrapper .contact_cta_wrapper {
    width: 100%;
    background: url("../../../assets/contact_cta_bg.png");
    min-height: 40vh;
    border-radius: 10px;
    padding: 4rem 0rem;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_wrapper .contact_cta_wrapper div {
    width: 300px;
    height: 300px;
    background-image: linear-gradient(270deg, #7a5900 0%, #7a5900 20%, #FEC600 100%);
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page_wrapper .contact_cta_wrapper div a {
    font-size: 30px;
    color: white;
    font-weight: 600;
}

.page_wrapper .contact_cta_wrapper div a .right_icon {
    margin-left: 2px;
    margin-bottom: -3px;
    font-size: 22px;
}


@media screen and (max-width: 1300px) {
    .page_wrapper .container .banner_wrapper {
        padding: 4rem 0rem;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text h2 {
        font-size: 44px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .banner_img {
        max-width: 440px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text p {
        font-size: 20px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons button {
        font-size: 18px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .img_overlay {
        display: none;
    }

    .page_wrapper .primary_about .content_wrapper .content .text h2 {
        font-size: 40px;
    }

    .page_wrapper .primary_about .content_wrapper .content .text {
        max-width: 55%;
    }

    .page_wrapper .primary_about .content_wrapper .content .about_img img {
        max-width: 95%;
    }

    .page_wrapper .primary_about .content_wrapper .about_overlay {
        display: none;
    }

    .page_wrapper .primary_about .content_wrapper .about_overlay .overlay_img_2 {
        display: none;
    }

    .page_wrapper .home_services_wrapper .services_content .services_row .services_item {
        min-width: 300px;
    }
}

@media screen and (max-width: 1190px) {
    .page_wrapper .primary_about .content_wrapper .content .text {
        max-width: 50%;
    }
}

@media screen and (max-width: 1000px) {
    .page_wrapper .primary_about .content_wrapper .content {
        flex-direction: column;
    }

    .page_wrapper .primary_about .content_wrapper .content .order_2 {
        order: inherit !important;
    }

    .page_wrapper .primary_about .content_wrapper .content .about_img {
        margin-bottom: 50px;
    }

    .page_wrapper .primary_about .content_wrapper .content .about_img img {
        max-width: 95%;
    }

    .page_wrapper .primary_about .content_wrapper .content .text {
        max-width: 98%;
        text-align: center;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text h2 {
        font-size: 40px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons button {
        font-size: 16px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text p {
        font-size: 18px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .banner_img {
        max-width: 350px;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text {
        width: 60%;
    }

    .page_wrapper .button_container {
        justify-content: center;
    }

    .page_wrapper .home_services_wrapper .services_content .services_row {
        flex-wrap: wrap;
    }

    .page_wrapper .testimonials_wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }

    .page_wrapper .testimonials_wrapper .text {
        max-width: 98%;
        text-align: center;
    }

    .page_wrapper .testimonials_wrapper .numbers {
        margin-top: 40px;
    }

    .page_wrapper .contact_cta_wrapper div {
        width: 200px;
        height: 200px;
    }

    .page_wrapper .contact_cta_wrapper div a {
        font-size: 24px;
    }

    .footer_wrapper .footer_row .footer_item .section_heading h2 {
        font-size: 28px;
    }

    .footer_wrapper .footer_row .footer_item .section_links ul li a {
        font-size: 16px;
    }

    .footer_wrapper .footer_row .footer_item .content h2 {
        font-size: 28px;
    }
}

@media screen and (max-width: 768px) {
    .page_wrapper .container .banner_wrapper .banner_overlay {
        flex-direction: column;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text {
        order: 2;
        margin-top: 50px;
        width: 98%;
        text-align: center;
    }

    .page_wrapper .primary_about {
        padding: 2rem 0;
    }

    .page_wrapper .primary_about .content_wrapper .content {
        margin-top: 50px !important;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .cta_buttons {
        justify-content: center;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text h2 {
        font-size: 34px;
    }

    .page_wrapper .primary_about .content_wrapper .content .text h2 {
        font-size: 34px;
    }

    .page_wrapper .primary_about .content_wrapper .content .text p {
        font-size: 16px;
    }

    .page_wrapper .button_container button {
        font-size: 16px;
    }

    .page_wrapper .home_services_wrapper .heading h2 {
        font-size: 34px;
    }

    .page_wrapper .home_services_wrapper .services_content .services_row .services_item .content h3 {
        font-size: 24px;
    }

    .page_wrapper .testimonials_wrapper .text h2 {
        font-size: 34px;
    }

    .footer_wrapper .footer_row {
        flex-wrap: wrap;
        gap: 50px;
    }

    .page_wrapper .container .banner_wrapper {
        padding: 2rem 0;
    }
}

@media screen and (max-width: 450px) {
    .page_wrapper .container .banner_wrapper .banner_overlay .text {
        max-width: 100%;
    }

    .page_wrapper .primary_about .content_wrapper .content .text {
        max-width: 100%;
    }

    .page_wrapper .container .banner_wrapper .banner_overlay .text h2 {
        font-size: 30px;
    }

    .page_wrapper .primary_about .content_wrapper .content .text h2 {
        font-size: 30px;
    }

    .page_wrapper .home_services_wrapper .heading h2 {
        font-size: 30px;
    }

    .page_wrapper .home_services_wrapper .services_content .services_row .services_item {
        max-width: 330px;
    }

    .page_wrapper .testimonials_wrapper .text h2 {
        font-size: 30px;
    }
}

@media screen and (max-width: 400px) {
    .page_wrapper .testimonials_wrapper .numbers {
        max-width: 98%;
    }
}

@media screen and (max-width: 360px) {
    .page_wrapper .testimonials_wrapper .numbers .row .item {
        padding: 10px;
    }

    .page_wrapper .testimonials_wrapper .numbers .row .item h3 {
        font-size: 24px;
    }

    .page_wrapper .testimonials_wrapper .numbers .row .item p {
        font-size: 16px;
    }
}