.page_not_found_wrapper {
    height: 100vh;
    flex-direction: column;
}

.page_not_found_wrapper h1 {
    font-size: 40px;
}

.page_not_found_wrapper a {
    font-size: 30px;
    display: block;
    color: white;
    text-decoration: underline;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.page_not_found_wrapper a .back_icon {
    margin-right: 10px;
}