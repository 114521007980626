.crm_page_wrapper {
    width: 100%;
    padding: 40px;
}

.crm_page_wrapper .page_title {
    text-align: left;
    margin-bottom: 20px;
}

.crm_page_wrapper .note {
    color: #fec600;
    font-weight: 500;
}

.crm_page_wrapper .button_container button {
    background: #fec600;
    color: white;
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #fec600;
    transition: all .3s ease-in-out;
    min-width: 100px;
}

.crm_page_wrapper .button_container button:hover {
    background: transparent;
    color: #fec600;
    cursor: pointer;
}

.crm_page_wrapper .search_filters_wrapper {
    width: 100%;
    margin-bottom: 30px;
}

.crm_page_wrapper .search_filters_wrapper .search_filters {
    display: flex;
    align-items: center;
    gap: 10px 40px;
}

@media screen and (max-width: 1400px) {
    .crm_page_wrapper .search_filters_wrapper .search_filters {
        flex-wrap: wrap;
    }
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item label {
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item .button_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item input {
    width: 100%;
    padding: 10px 16px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3s ease-in-out;
    color: white;
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item input:focus {
    border-bottom: 1px solid #fec600;
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item select {
    width: 100%;
    padding: 10px 16px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3s ease-in-out;
    color: white;
    min-width: 200px;
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item select option {
    color: black;
}

.crm_page_wrapper .search_filters_wrapper .search_filters .search_item select:focus {
    border-bottom: 1px solid #fec600;
}

.crm_page_wrapper .order_details_container {
    overflow-x: auto;
    max-width: 100%;
}

.crm_page_wrapper .order_details_container::-webkit-scrollbar {
    height: 6px;
}

.crm_page_wrapper .order_details_container::-webkit-scrollbar-track {
    background-color: #1f2940;
    border-radius: 5px;
}

.crm_page_wrapper .order_details_container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
    cursor: pointer;
}

.crm_page_wrapper .order_details_container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.crm_page_wrapper .order_details_container #orders {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
    table-layout: auto;
    overflow-x: auto;
}

.crm_page_wrapper .order_details_container #orders th,
.crm_page_wrapper .order_details_container #orders td {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 15px;
    border-bottom: 1px solid #cccc;
}

.crm_page_wrapper .order_details_container #orders th {
    font-size: 18px;
    background-color: #f4f4f419;
    font-weight: 600;
}

.crm_page_wrapper .order_details_container #orders tr:hover {
    background-color: #cccccc36;
    cursor: pointer;
}

.crm_page_wrapper .order_details_container #orders td {
    vertical-align: middle;
}

.crm_page_wrapper .order_details_container button:disabled {
    background: grey;
    border: 1px solid grey;
    cursor: not-allowed;
}

.crm_page_wrapper .order_details_container button:disabled:hover {
    color: grey;
    background: transparent;
}

.crm_page_wrapper .file_upload_section {
    margin-bottom: 30px;
}

.crm_page_wrapper .file_upload_section button {
    background: #fec600;
    padding: 8px 16px;
    color: white;
    border-radius: 4px;
    border: 1px solid #fec600;
    transition: all 0.3s ease-in-out;
}

.crm_page_wrapper .file_upload_section button:hover {
    cursor: pointer;
    background: transparent;
    color: #fec600;
}

.crm_page_wrapper .file_upload_section h3 {
    margin-bottom: 10px;
}

.crm_page_wrapper .order_details_container #orders .delete_icon {
    font-size: 18px;
    cursor: pointer;
}

.crm_page_wrapper .order_details_container .btn {
    padding: 8px 16px;
    color: white;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.crm_page_wrapper .order_details_container .success {
    background: green;
    border: 1px solid green;
}

.crm_page_wrapper .order_details_container .failed {
    background: red;
    border: 1px solid red;
}

.no_of_rows label {
    font-weight: 600;
    min-width: 100px;
    display: block;
    margin-right: 10px;
    margin-bottom: -5px;
}

.no_of_rows select {
    width: 100%;
    padding: 10px 16px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3s ease-in-out;
    color: white;
    min-width: 100px;
}

.no_of_rows select option {
    color: black;
}

.no_of_rows select:focus {
    border-bottom: 1px solid #fec600;
}

.no_of_rows input {
    width: 100%;
    padding: 10px 16px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3s ease-in-out;
    color: white;
    min-width: 100px;
}

.no_of_rows input:focus {
    border-bottom: 1px solid #fec600;
}

.control_btns button {
    padding: 6px 10px;
    margin: 0px 10px;
    color: white;
    background: #fec600;
    border: 1px solid #fec600;
    border-radius: 4px;
}

.control_btns button:hover {
    background: transparent;
    color: #fec600;
}

.control_btns button:disabled {
    background: grey;
    border: 1px solid grey;
    cursor: not-allowed;
}

.control_btns button:disabled:hover {
    background: transparent;
    color: grey;
}

/* sliding window css  */
.sliding_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease, visibility 0.3s ease;
    z-index: 1;
}

.sliding_background.show {
    opacity: 1;
    visibility: visible;
}

.sliding_background .sliding_content {
    position: fixed;
    right: 0;
    top: 0;
    width: 700px;
    height: 100vh;
    background: white;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform .5s ease-in-out;
    z-index: 2;
    overflow-y: scroll;
}

.sliding_background.show .sliding_content {
    transform: translateX(0);
}

.sliding_background .sliding_content .title {
    padding: 10px 20px;
    text-align: center;
    background: #1f2940;
}

.sliding_background .sliding_content .title .id {
    display: flex;
    justify-content: space-between;
    max-width: 60%;
    margin: 0 auto;
    min-width: 400px;
}

.sliding_background .sliding_content .title .close_container {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;
}

.sliding_background .sliding_content .title .close_container .close_icon {
    font-size: 30px;
    cursor: pointer;
}

.sliding_background .sliding_content .title .supplier_details .supplier_item {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.sliding_background .sliding_content .title .supplier_details .supplier_item p {
    font-weight: 600;
}

.sliding_background .sliding_content .popup_table {
    color: #1f2940;
}

.sliding_background .sliding_content .specific_order_container {
    overflow-x: auto;
}

.sliding_background .sliding_content .specific_order_container #orders {
    margin-top: 5px;
    width: 100%;
    padding: 4px 10px;
    border-collapse: collapse;
    table-layout: auto;
    overflow-x: auto;
}

.sliding_background .sliding_content .specific_order_container #orders th,
.sliding_background .sliding_content .specific_order_container #orders td {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 15px;
    border-bottom: 1px solid #cccc;
}

.sliding_background .sliding_content .specific_order_container #orders th {
    font-size: 18px;
    background-color: #f4f4f419;
    font-weight: 600;
}

.sliding_background .sliding_content .specific_order_container #orders tr:hover {
    background-color: #cccccc36;
    cursor: pointer;
}

.sliding_background .sliding_content .specific_order_container #orders td {
    vertical-align: middle;
}

.no-scroll {
    overflow: hidden !important;
    height: 100vh;
}

.sliding_background .sliding_content .button_container {
    display: flex;
    justify-content: center;
    padding: 20px 0px 10px 0px;
}

.sliding_background .sliding_content .button_container button {
    padding: 8px 16px;
    background: #fec600;
    color: white;
    border: 1px solid #fec600;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.sliding_background .sliding_content .button_container button:hover {
    background: transparent;
    color: #fec600;
    cursor: pointer;
}

.pagination_controls {
    margin-bottom: 10px;
}