.super_admin_page_wrapper {
    width: 100%;
    padding: 40px;
}

@media screen and (max-width: 600px) {
    .super_admin_page_wrapper {
        padding: 40px 10px;
    }
}

.super_admin_page_wrapper .add_cust_btn {
    background: #fec600;
    color: white;
    border: 1px solid #fec600;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    min-width: 141px;
}

.super_admin_page_wrapper .add_cust_btn:hover {
    cursor: pointer;
    background: transparent;
    color: #fec600;
}

.super_admin_page_wrapper .welcome_note {
    text-align: left;
}

.super_admin_page_wrapper .welcome_note h2 {
    font-size: 28px;
    font-weight: 500;
}

.super_admin_page_wrapper .admin_details {
    width: 100%;
    margin-top: 50px;
}

.super_admin_page_wrapper .admin_details .admin_list {
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
}

.super_admin_page_wrapper .admin_details .admin_list th, .super_admin_page_wrapper .admin_details .admin_list td {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 10px 20px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    border-bottom: 1px solid #cccc; 
}

.super_admin_page_wrapper .admin_details .admin_list th {
    font-size: 18px;
    background-color: #f4f4f419; 
    font-weight: 600;
}

.super_admin_page_wrapper .admin_details .admin_list tr:hover {
    background-color:#cccccc36;
}

.super_admin_page_wrapper .admin_details .admin_list td {
    vertical-align: middle;
    border-bottom: 1px solid #cccccc2d;
}

.settings_icon {
    cursor: pointer;
    font-size: 20px;
}
