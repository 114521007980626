.wrapper {
    width: 100%;
    min-height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper .form_container {
    min-width: 300px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.wrapper .form_container .heading {
    text-align: center;
    width: 100%;
}

.wrapper .form_container .heading h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: white;
}

.wrapper .form_container .input_row {
    margin-top: 30px;
    width: 100%;
    position: relative;
}

.wrapper .form_container .input_row .password_toggle_icon {
    color: white;
    position: absolute;
    font-size: 22px;
    top: 40px;
    right: 15px;
}

.wrapper .form_container .input_row label {
    color: white;
    display: block;
    font-size: 20px;
    margin-bottom: 8px;
}

.wrapper .form_container .input_row input {
    width: 100%;
    padding: 10px 16px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    transition: all 0.3s ease-in-out;
    color: white;
}

.wrapper .form_container .input_row input:focus {
    border-bottom: 1px solid #fec600;
}

.wrapper .form_container .account_register_note {
    text-align: center;
    margin-top: 10px;
    font-weight: 400;
    color: white;
    font-size: 18px;
}

.wrapper .form_container .account_register_note a {
    color: white;
    text-decoration: underline;
}

.wrapper .form_container .button_container button {
    padding: 10px 16px;
    background: #fec600;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid #fec600;
    transition: all 0.3s ease-in-out;
    min-width: 100px;
}

.wrapper .form_container .button_container button:hover {
    background-color: transparent;
    border: 1px solid #fec600;
    cursor: pointer;
}

.wrapper .form_container .errormsg {
    text-align: center;
    color: red;
    margin: 8px 0px;
    font-weight: 600;
}