.page_wrapper {
    width: 100%;
}

.page_wrapper .container .about_banner {
    width: 100%;
    background: #1c2024;
    padding: 3rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page_wrapper .container .about_banner .banner_content h2{
    font-size: 40px;
    color: white;
    margin-bottom: 20px;
    text-align: center;
}

.page_wrapper .container .about_banner .banner_content .location_specifier {
    background: linear-gradient(rgba(242, 220, 220, 0.1), rgba(242, 220, 220, 0.1));
    padding: 20px 40px;
    border-left: 1px solid #FEC600;
    border-radius: 4px;
}

.page_wrapper .container .about_banner .banner_content .location_specifier p {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.page_wrapper .container .about_banner .banner_content .location_specifier p .right_ico {
    font-size: 20px;
    margin: 0px 2px;
    margin-bottom: -5px;
}

.page_wrapper .about_us_section_1 {
    width: 100%;
    padding: 2rem 0rem;
}

.page_wrapper .about_us_section_1 .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page_wrapper .about_us_section_1 .wrapper .text {
    text-align: left;
    max-width: 50%;
    padding-bottom: 60px;
    border-bottom: 2px solid #FEC600;
}

.page_wrapper .about_us_section_1 .wrapper .text h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.page_wrapper .about_us_section_1 .wrapper .text p {
    font-size: 18px;
    line-height: 1.6;
    font-family: 500;
    color: #9ea1a5;
}

.page_wrapper .about_us_section_1 .wrapper .text .mini_service {
    display: flex;
    max-width: 90%;
    margin: 50px auto 0px auto;
}

.page_wrapper .about_us_section_1 .wrapper .text .mini_service .item {
    max-width: 300px;
}

.page_wrapper .about_us_section_1 .wrapper .text .mini_service .item .heading {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.page_wrapper .about_us_section_1 .wrapper .text .mini_service .item .heading h4 {
    font-size: 20px;
}

.page_wrapper .about_us_section_1 .wrapper .text .mini_service .item .heading .about_icon {
    font-size: 24px;
    margin-right: 5px;
    color: #FEC600;
}

.page_wrapper .about_us_section_2 {
    width: 100%;
    padding: 2rem 0rem;
}

.page_wrapper .about_us_section_2 .content_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order_2 {
    order: 2;
}

.text_2 {
    max-width: 45% !important;
    border-bottom: none !important;
}

.page_wrapper .about_cta {
    width: 100%;
    background-color: #1E2C3A;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.page_wrapper .about_cta .cta_container {
    text-align: center;
}

.page_wrapper .about_cta .cta_container h2 {
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.page_wrapper .about_cta .cta_container p {
    color: #9ea1a5;
    font-size: 18px;
    max-width: 80%;
    margin: 0 auto;
    line-height: 1.5;
}

.page_wrapper .about_cta .button_container {
    margin-top: 50px;
}

@media screen and (max-width: 1300px) {
    .page_wrapper .about_us_section_1 .wrapper  .image img {
        max-width: 80%;
        min-width: 300px;
    }

    .page_wrapper .about_us_section_1 .wrapper {
        flex-direction: column;
    }

    .page_wrapper .about_us_section_1 .wrapper .text {
        margin-top: 50px;
        max-width: 98%;
        text-align: center;
    }

    .page_wrapper .about_us_section_1 .wrapper .text .mini_service {
        max-width: 80%;
        justify-content: space-between;
    }

    .page_wrapper .about_us_section_1 .wrapper .text p {
        text-align: left;
    }

    .text_2 {
        max-width: 98% !important;
        order: 2;
    }

    .page_wrapper .container .about_banner .banner_content h2 {
        font-size: 34px;
    }

    .page_wrapper .container .about_banner .banner_content .location_specifier {
        padding: 10px 20px;
    }

    .page_wrapper .about_cta .cta_container h2 {
        font-size: 34px;
    }

    .page_wrapper .about_us_section_1 .wrapper .text h2 {
        font-size: 34px;
    }
}

@media screen and (max-width: 768px) {
    .page_wrapper .about_us_section_1 .wrapper .text .mini_service {
        max-width: 98%;
    }

    .page_wrapper .about_us_section_1 .wrapper .image {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .page_wrapper .about_us_section_1 .wrapper .text p {
        font-size: 16px;
    }

    .page_wrapper .about_cta .cta_container p {
        font-size: 16px;
        max-width: 98%;
    }

    .page_wrapper .about_cta {
        padding: 1.8rem .3rem;
    }
}

@media screen and (max-width: 400px) {
    .page_wrapper .about_us_section_1 .wrapper .text .mini_service {
        flex-direction: column;
        align-items: center;
    }
    .page_wrapper .about_us_section_1 .wrapper .text .mini_service .item{
        margin-top: 30px;
    }

    .page_wrapper .about_us_section_1 .wrapper .image img {
        max-width: 90%;
    }

    .page_wrapper .about_cta .cta_container h2 {
        font-size: 30px;
    }
}