.page_wrapper {
    width: 100%;
}

.page_wrapper .container .contact_banner {
    width: 100%;
    background: #1c2024;
    padding: 3rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page_wrapper .container .contact_banner .banner_content h2 {
    font-size: 40px;
    color: white;
    margin-bottom: 20px;
    text-align: center;
}

.page_wrapper .container .contact_banner .banner_content .location_specifier {
    background: linear-gradient(rgba(242, 220, 220, 0.1), rgba(242, 220, 220, 0.1));
    padding: 20px 40px;
    border-left: 1px solid #FEC600;
    border-radius: 4px;
}

.page_wrapper .container .contact_banner .banner_content .location_specifier p {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.page_wrapper .container .contact_banner .banner_content .location_specifier p .right_ico {
    font-size: 20px;
    margin: 0px 2px;
    margin-bottom: -5px;
}

.page_wrapper .contact_details_wrapper {
    width: 100%;
    padding: 6rem 0rem;
}

.page_wrapper .contact_details_wrapper .contact_row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.page_wrapper .contact_details_wrapper .contact_row .contact_item {
    background: #1c2024;
    padding: 30px 25px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 290px;
    border-radius: 8px;
}

.page_wrapper .contact_details_wrapper .contact_row .contact_item .contact_icon_container {
    background: #FEC600;
    padding: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.page_wrapper .contact_details_wrapper .contact_row .contact_item .contact_icon_container .contact_icon {
    font-size: 26px;
}

.page_wrapper .contact_details_wrapper .contact_row .contact_item .text {
    margin-left: 50px;
}

.page_wrapper .contact_details_wrapper .contact_row .contact_item .text span {
    font-size: 18px;
}

.page_wrapper .contact_details_wrapper .contact_row .contact_item .text p {
    font-size: 22px;
    font-weight: 500;
    margin-top: 10px;
    color: #FEC600;
}

.page_wrapper .contact_form_wrapper {
    width: 100%;
    padding: 4rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page_wrapper .contact_form_wrapper .contact_img {
    max-width: 50%;
}

.page_wrapper .contact_form_wrapper .contact_img img {
    border-radius: 4px;

}

.page_wrapper .contact_form_wrapper .form_section {
    min-width: 300px;
    margin-left: 50px;
    width: 50%;
}

.page_wrapper .contact_form_wrapper .form_section h2 {
    text-align: left;
    font-size: 40px;
    margin-bottom: 40px;
}

.page_wrapper .contact_form_wrapper .form_section form {
    width: 100%;
}

.page_wrapper .contact_form_wrapper .form_section form .input_row {
    margin-top: 20px;
    display: flex;
    width: 100%;
    gap: 40px;
}

.page_wrapper .contact_form_wrapper .form_section form .input_row .item {
    flex: 1;
}

.page_wrapper .contact_form_wrapper .form_section form .input_row .item input {
    background: transparent;
    padding: 10px 16px;
    width: 100%;
    min-width: 200px;
    border: none;
    border-bottom: 1px solid #cccc;
    border-radius: 4px;
    color: white;
    font-size: 16px;
}

.page_wrapper .contact_form_wrapper .form_section form .input_row .item input:focus {
    outline: none;
    border-bottom: 1px solid #FEC600;
}

.page_wrapper .contact_form_wrapper .form_section form .input_row .item textarea {
    background: transparent;
    padding: 10px 16px;
    width: 100%;
    min-width: 200px;
    border: none;
    border-bottom: 1px solid #cccc;
    border-radius: 4px;
    color: white;
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    .page_wrapper .contact_details_wrapper .contact_row {
        flex-wrap: wrap;
        gap: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .page_wrapper .container .contact_banner .banner_content h2 {
        font-size: 34px;
    }

    .page_wrapper .container .contact_banner .banner_content .location_specifier {
        padding: 10px 20px;
    }

    .page_wrapper .contact_form_wrapper .form_section h2 {
        font-size: 34px;
    }
}

@media screen and (max-width: 800px) {
    .page_wrapper .contact_form_wrapper {
        flex-direction: column;
    }

    .page_wrapper .contact_form_wrapper .contact_img {
        max-width: 80%;
        margin: 0px auto 50px auto;
    }

    .page_wrapper .contact_form_wrapper .form_section {
        margin-left: 0px;
    }

    .page_wrapper .contact_form_wrapper .form_section form .input_row .item input {
        min-width: 250px;
    }

    .page_wrapper .contact_form_wrapper .form_section {
        width: 98%;
    }

    .page_wrapper .contact_form_wrapper .form_section h2 {
        text-align: center;
    }

    .page_wrapper .button_container {
        display: flex;
    }
}

@media screen and (max-width: 600px) {
    .page_wrapper .contact_form_wrapper .form_section form .input_row {
        flex-wrap: wrap;
    }

    .page_wrapper .container .contact_banner {
        width: 98%;
        margin: 0 auto;
        padding: 2rem 0rem;
    }
}


@media screen and (max-width: 450px) {
    .page_wrapper .contact_form_wrapper .form_section form .input_row {
        flex-wrap: wrap;
    }

    .page_wrapper .contact_form_wrapper .contact_img img {
        width: 100%;
    }

    .page_wrapper .contact_details_wrapper .contact_row {
        justify-content: center;
    }

    .page_wrapper .contact_details_wrapper .contact_row .contact_item {
        flex: 1;
        max-width: 330px;
        padding: 20px 10px;
        flex-direction: column;
    }

    .page_wrapper .contact_details_wrapper .contact_row .contact_item .text {
        margin-left: 0px;
        text-align: center;
        margin-top: 25px;
    }

} 