.loader {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #fec600;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin .8s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }