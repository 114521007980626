.crm_page_wrapper {
    width: 100%;
}

.crm_page_wrapper .card_details h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.crm_page_wrapper .card_details .data_row {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    max-width: 700px;
}

.crm_page_wrapper .card_details .card_note {
    font-weight: 500;
}

.crm_page_wrapper .card_details .data_row .key {
    display: block;
    font-size: 18px;
    font-weight: 600;
    min-width: 180px;
}

.crm_page_wrapper .card_details .data_row .value {
    font-size: 18px;
    font-weight: 500;
}

.crm_page_wrapper .card_details .button_container {
    margin-left: 120px;
    margin-top: 30px;
}

.crm_page_wrapper .card_details .button_container button {
    padding: 8px 16px;
    color: white;
    background: #fec600;
    border-radius: 4px;
    border: 1px solid #fec600;
    transition: all 0.3s ease-in-out;
    margin-right: 20px;
    font-weight: 600;
}

.crm_page_wrapper .card_details .button_container button:hover {
    background: transparent;
    color: #fec600;
    cursor: pointer;
}

.crm_page_wrapper .card_details .data_row input {
    padding: 6px 12px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    border-radius: 4px;
    min-width: 280px;
    transition: all 0.3s ease-in-out;
    color: white;
}

.crm_page_wrapper .card_details .data_row input:hover {
    border-bottom: 1px solid #fce600;
}

.crm_page_wrapper .card_details .data_row select {
    padding: 6px 12px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccc;
    border-radius: 4px;
    min-width: 280px;
    transition: all 0.3s ease-in-out;
    color: white;
}

.crm_page_wrapper .card_details .data_row select:hover {
    border-bottom: 1px soliod #fec600;
} 

.crm_page_wrapper .card_details .data_row select option {
    color: black;
}