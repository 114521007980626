.sidebar {
    width: 240px;
    height: 100%;
    background: #1f2940;
    padding: 20px 30px;
    position: sticky;
    top: 0;
    left: 0;
    min-height: 100vh;
}

.sidebar .user_profile {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar .user_profile .icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.sidebar .user_profile .icon_container .user_icon {
    font-size: 30px;
}

.sidebar .user_profile .user_name {
    text-align: center;
}

.sidebar .user_profile .user_name p {
    font-size: 24px;
    font-weight: 600;
}

.sidebar ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sidebar .submenu_list {
    margin-left: 25px;
    gap: 20px;
    margin-top: -14px;
}

.sidebar ul li a {
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.sidebar ul li .active {
    color: #FEC600;
}

.sidebar ul li a .nav_icon {
    margin-right: 8px;
    margin-bottom: -5px;
    font-size: 20px;
}

.sidebar ul button .menu_icon {
    margin-right: 5px;
    margin-bottom: -2px;
}

.sidebar ul li button .nav_icon {
    font-size: 22px;
    margin-bottom: -3px;
    margin-right: 5px;
}

.sidebar ul li button:hover {
    cursor: pointer;
}

.sidebar .sidebar_list button {
    color: white;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: none;
    border: none;
}

.sidebar .sidebar_list button:hover {
    cursor: pointer;
}

.sidebar .sidebar_list .caret_icon {
    font-size: 19px;
    margin-bottom: -4px;
    margin-left: 1px;
}