.page_wrapper {
    width: 100%;
}

.page_wrapper .heading {
    width: 100%;
    text-align: center;
}

.page_wrapper .heading h2 {
    font-size: 40px;
}

.page_wrapper .legal_content {
    margin-top: 30px;
}

.page_wrapper .legal_content p {
    color: #9ea1a5;
    margin-bottom: 10px;
    font-weight: 500;
}

.page_wrapper .legal_content h3 {
    margin-top: 20px;
}

.page_wrapper .legal_content ul {
    margin-left: 20px;
}

.page_wrapper .legal_content ul li {
    list-style: number;
    color: #9ea1a5;
}

@media  screen and (max-width: 768px) {
    .page_wrapper .heading h2 {
        font-size: 34px;
    }
}