.crm_page_wrapper {
    width: 100%;
    padding: 40px;
}

.crm_page_wrapper .sms_url_container {
    margin-bottom: 20px;
}

.crm_page_wrapper .sms_url_container h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.crm_page_wrapper .sms_url_container #link {
    font-size: 18px;
    margin-right: 20px;
    font-weight: 500;
}

.crm_page_wrapper .sms_url_container button {
    background: #FEC600;
    color: white;
    border: 1px solid #FEC600;
    padding: 6px 12px;
    border-radius: 4px;
    font-weight: 600;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.crm_page_wrapper .sms_url_container button:hover {
    background: transparent;
    color: #FEC600;
    cursor: pointer;
}

.crm_page_wrapper .sms_note {
    margin-top: 10px;
    font-family: 500;
    font-size: 15px;
}


.crm_page_wrapper .sms_note p {
    color: #FEC600;
}

.crm_page_wrapper .sms_url_container .tooltip {
    color: red;
    font-weight: 500;
    margin-left: 10px;
}

.crm_page_wrapper .card_details_container {
    margin-top: 30px;
}

.crm_page_wrapper .card_details_container h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.crm_page_wrapper .card_details_container #cards {
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
    table-layout: fixed;
}

.crm_page_wrapper .card_details_container #cards th, .crm_page_wrapper .card_details_container #cards td{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 10px 20px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    border-bottom: 1px solid #cccc; 
}

.crm_page_wrapper .card_details_container #cards th {
    font-size: 18px;
    background-color: #f4f4f419;
    font-weight: 600;
}

.crm_page_wrapper .card_details_container #cards tr:hover {
    background-color:#cccccc36;
    cursor: pointer;
}

.crm_page_wrapper .card_details_container #cards td {
    vertical-align: middle;
}

.crm_page_wrapper .card_details_container .button_container button {
    background: #FEC600;
    color: white;
    border: 1px solid #FEC600;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 600;
    outline: none;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
    font-weight: 500;
}

.crm_page_wrapper .card_details_container .button_container button:hover {
    cursor: pointer;
    background: transparent;
    color: #FEC600;
}