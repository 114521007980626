.crm_page_wrapper {
    width: 100%;
}

.crm_page_wrapper .subsciption_status {
    margin-bottom: 20px;
}

.crm_page_wrapper .subsciption_status h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.crm_page_wrapper .subsciption_status p {
    font-size: 18px;
    font-weight: 500;
}

.crm_page_wrapper .susbcription_purchase {
    margin-bottom: 20px;
}

.crm_page_wrapper .susbcription_purchase h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.crm_page_wrapper .susbcription_purchase p {
    font-size: 18px;
    font-weight: 500;
}

.crm_page_wrapper .susbcription_purchase .counter_btn {
    background: #fec600;
    padding: 4px 8px;
    color: white;
    align-items: center;
    font-size: 18px;
    border-radius: 8px;
    margin-right: 8px;
    border: 1px solid #fec600;
}

.crm_page_wrapper .susbcription_purchase .counter_btn:hover {
    cursor: pointer;
    background: transparent;
}

.crm_page_wrapper .susbcription_purchase .btn {
    background: #fec600;
    color: white;
    padding: 6px 12px;
    font-weight: 500;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 15px;
    margin-left: 50px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #fec600;
}

.crm_page_wrapper .susbcription_purchase .btn:hover {
    cursor: pointer;
    background: transparent;
}