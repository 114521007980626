@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

body {
  font-family: "Rajdhani", sans-serif;
  background-color: #000C1F;
  color: white;
}

.container {
  max-width: 90%;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 98%;
  }

}